.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.tnone{
  text-decoration: none !important;
}
.tnone .card{
  background: transparent;
    color: #fff;
    border: 1px solid #b03dd3;
    transition: 0.5s;
}
.whtclr{
  color: #fff;
}
.copyimg{
  color: #62e729;
}
.cursor{
  cursor: pointer;
}
.tnone .card:hover{
  transition: 0.5s;
  background: transparent;
  color: #b03dd3;
  border: 1px solid #b03dd3;
}
.date_h{
  height: 50px;
  color: #fff;
  border: 0;
}
.date_h:focus-visible{
  outline: 0;
}
.poss{
  position: relative;
  z-index: 4;
}
.uploadimg{
  position: absolute;
}
.browseinput{
  opacity: 0;
}
.hw{
  height: 100px;
  width: 100px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
